import Testseries from "./Testseries";
const Parallaxcard = () => {
  return (
    <>
      <div className="parallax">
        <div  className="parallax_text">
            <Testseries/>
        </div>
      </div>

    </>
  );
};

export default Parallaxcard;
